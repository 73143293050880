import { Provider } from "react-redux";

import Goals from "./SubGoals.js";
import Timers from "./Timers.js";
import { store } from "./root.js";
import Home from "./Home.js";
import Div from "./Div.js";
import useViewport from "./hooks/useViewport.js";

export default function App() {
  const { height, width } = useViewport();

  return (
    <Provider store={store}>
      <Div style={{ height, padding: 16, width }}>
        <Div style={{ margin: "0 auto", maxWidth: 960 }}>
          <Home />
          {
            // <Goals />
            // <Timers />
          }
          {
            // timer: goal, break
          }
        </Div>
      </Div>
    </Provider>
  );
}
