import { useEffect, useState } from "react";
import { connect } from "react-redux";

import GoalInput from "./GoalInput.js";
import SubGoals from "./SubGoals.js";
import nanoid from "./nanoid.js";
import {
  completeGoal,
  deleteGoal,
  setGoal,
  //
} from "./root.js";

export default connect(
  (
    {
      goalsMap,
      // selected
    },
    {
      ancestorFocused,
      id,
      // inputRef,
      parent,
      setEmpty,
      //   setFocused,
      signalBlur,
      signalFocus,
    },
  ) => ({
    ancestorFocused,
    id,
    body: goalsMap[id]?.body ?? null,
    comment: goalsMap[id]?.comment ?? null,
    // inputRef,
    // parent: goalsMap[id]?.parent ?? parent ?? null,
    parent,
    // selected: selected !== null && selected === id,
    setEmpty,
    // setFocused,
    signalBlur,
    signalFocus,
  }),
  {
    completeGoal,
    deleteGoal,
    setGoal,
  },
)(function Goal({
  ancestorFocused,
  body,
  comment,
  completeGoal,
  deleteGoal,
  id,
  //   inputRef,
  parent,
  selected, // if is only goal, should be selected
  setEmpty,
  //   setFocused,
  setGoal,
  signalBlur,
  signalFocus,
}) {
  // how differentiate between a set goal and not set?
  // existence of id?
  const [_body, set_body] = useState(body ?? "");
  const [focused, setFocused] = useState(false);
  //   const [_comment, set_comment] = useState(comment ?? "");

  //   //   const [_id, set_id] = useState(id);
  //   useEffect(() => {
  //     set_id(id ?? nanoid());
  //   }, [id]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // borderColor: "blue",
        // borderStyle: "solid",
        // borderWidth: selected === true ? 1 : 0,
        // borderWidth: 1,
        marginLeft: 16,
        marginTop: 16,
        outlineColor: "blue",
        outlineOffset: 8,
        outlineStyle: "solid",
        outlineWidth: focused === true ? 1 : 0,
        // outlineWidth: 0,
        // with padding complete/delete buttons are aligned poorly
        // without, the selected goal border has no room to breathe
        // padding: 4,
      }}
    >
      {/* {JSON.stringify({ b1: body, b2: _body }, null, 2)} */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <input
          onBlur={_onBlur}
          onChange={_onChange}
          onFocus={_onFocus}
          placeholder="Goal"
          //   ref={inputRef}
          style={{
            // marginRight: 8,
            minWidth: 160,
          }}
          type="text"
          value={_body}
        />
      </div>
      {
        // button to start working on a goal
        // will start timer too

        id != null && (
          <SubGoals ancestorFocused={ancestorFocused || focused} parent={id} />
        )
      }
    </div>
  );

  function _onBlur() {
    // setEmpty?.(true);
    // setTimeout(() => setEmpty?.(true), 1);

    // delete on empty goals and non-null id?
    if (!_body) {
      if (id != null) {
        deleteGoal({ id, time: new Date() });
      }
      setFocused(false);
      signalBlur?.();
      //   setTimeout(() => signalBlur?.(), 1);
      return;
    }

    // have parent call setGoal?
    // how does that prevent render flash?
    if (_body !== body) {
      setGoal({
        body: _body,
        //   comment,
        id: id ?? nanoid(),
        parent,
        time: new Date(),
      });
      //   set_body("");
      setEmpty?.(true);
    }
    setFocused(false);
    signalBlur?.();
    // setTimeout(() => signalBlur?.(), 1);
  }

  // show subgoal input on nonempty body
  // solutin: set goal on each letter?
  function _onChange(event) {
    // console.log(setEmpty);
    set_body(event.target.value);
    setEmpty?.(event.target.value.length === 0);
  }

  function _onFocus() {
    setFocused?.(true);
    signalFocus?.();
  }
});

// <div>
// <button>edit</button>
// <button>complete</button>
// <button>delete</button>
// </div>
