import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { delay, put, take, takeEvery } from "redux-saga/effects";

const sagaMiddleware = createSagaMiddleware();

// const store = createStore(reducer, applyMiddleware(sagaMiddleware));
// https://github.com/zalmoxisus/redux-devtools-extension?tab=readme-ov-file#12-advanced-store-setup
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);
sagaMiddleware.run(sagas);

export { store };

function reducer(
  state = {
    goalsMap: {},
    selected: null,
    // sort: "newest-first", // newest, oldest
  },
  action,
) {
  switch (action.type) {
    case "COMPLETE_GOAL": {
      return {
        ...state,
        goalsMap: {
          ...state.goalsMap,
          [action.payload.id]: {
            ...state.goalsMap[action.payload.is],
            completed: true,
            stopped: [
              ...(state.goalsMap[action.payload.id] ?? []),
              action.payload.time,
            ], // start stop should be arrays. otherwise if span multiple sessions, time is too long
          },
        },
      };
    }
    case "DELETE_GOAL": {
      const goalsMap = { ...state.goalsMap };
      _goalDescendents(action.payload.id).forEach(id => delete goalsMap(id));
      delete goalsMap[action.payload.id];
      return {
        ...state,
        goalsMap,
      };
    }
    case "SET_GOAL": {
      const { id, ...goal } = action.payload;
      return {
        ...state,
        goalsMap: {
          ...state.goalsMap,
          [id]: { ...goal },
        },
      };
    }
    default:
      return state;
  }

  function _goalDescendents(id) {
    const children = Object.keys(state.goalsMap).filter(
      goal => state.goalsMap[goal].parent === id,
    );
    if (children.length === 0) {
      return [];
    }
    return [
      ...children,
      ...children.reduce(
        (acc, child) => [...acc, ..._goalDescendents(child)],
        [],
      ),
    ];
  }
}

function* sagas() {
  //
}

export function completeGoal({ id }) {
  return { payload: { id }, type: "COMPLETE_GOAL" };
}

export function deleteGoal({ id }) {
  return { payload: { id }, type: "DELETE_GOAL" };
}

export function setGoal({
  body,
  comment = null,
  id,
  parent,
  time,
  //
}) {
  return {
    payload: {
      body,
      comment,
      id,
      parent,
      time,
      //
    },
    type: "SET_GOAL",
  };
}
