import { useState } from "react";
import { connect } from "react-redux";

import Goal from "./Goal.js";
import { useRef } from "react";
import { useEffect } from "react";
// import GoalInput from "./GoalInput.js";

export default connect(
  ({ goalsMap }, { ancestorFocused = false, parent = null }) => ({
    goals: Object.keys(goalsMap).filter(id => goalsMap[id].parent === parent),
    parent,
  }),
  {
    //
  },
)(function SubGoals({ ancestorFocused, goals, parent }) {
  //   const [empty, setEmpty] = useState(true);
  // #region
  //   const [focus, setFocus] = useState(false);
  //   const newGoalRef = useRef(null);

  //   useEffect(() => {
  //     if (empty && focus) {
  //       setFocus(false);
  //       newGoalRef.current?.focus?.();
  //     }
  //   }, [empty]);
  // #endregion

  const [childrenFocused, setChildrenFocused] = useState(0);
  const [empty, setEmpty] = useState(true);
  const [renderedGoals, setRenderedGoals] = useState([...goals, null]);

  // but does this cause a rerender when second to last gets an id?

  // when last goalinput, an unsaved goal, becomes nonempty, append a new empty goalinput as last
  // when non last goal input, becomes empty, remove self, either by redux/id if saved and somehow if unsaved
  useEffect(() => {
    // console.log(new Date(), { empty, goals, parent });
    // will this cause rerender on change from empty -> nonempty?
    if (empty) {
      setRenderedGoals([...goals, null]);
      return;
    }
    setRenderedGoals([...goals, null, null]);
  }, [
    empty,
    goals,
    //
  ]);

  return (
    <>
      {/* <div>af: {ancestorFocused ? "true" : "false"}</div> */}
      <div>cf: {childrenFocused}</div>
      {/* <div>
        df:
        {
          // descendentFocused ? "true" : "false"
        }
      </div> */}
      {
        renderedGoals.map((id, i) =>
          // filter goals somehow that it's still in focused array to prevent re-render
          // setID or something that lets child tell parent what id is generated?
          // or something like that

          // what about if child focused? or first goal?
          // when show inputs?
          // modes: view mode, input mode, timer mode

          id !== null || (!empty && i === renderedGoals.length - 1) ? (
            // (!empty && ancestorFocused && i === renderedGoals.length - 1) ? (
            <Goal
              ancestorFocused={ancestorFocused}
              id={id}
              key={i}
              parent={parent}
              // setID={setID}
              signalBlur={blurChild}
              signalFocus={focusChild}
            />
          ) : (
            // ) : !ancestorFocused && childrenFocused === 0 ? null : (
            <Goal
              ancestorFocused={ancestorFocused}
              id={id}
              key={i}
              parent={parent}
              setEmpty={setEmpty}
              // setID={setID}
              signalBlur={blurChild}
              signalFocus={focusChild}
            />
          ),
        )
        //
      }
    </>
  );

  function blurChild() {
    console.log("blur child");
    setChildrenFocused(c => c - 1);
  }
  function focusChild() {
    console.log("focus child");
    setChildrenFocused(c => c + 1);
  }
});

// what about creating a new goal?
// need to be able to create a new goal at each level
// but that's sibling not children
// so just an input type="text". if have content, then show rest of stuff

{
  // #region
  // only show if focused/selected?
  // show new one if current one has non-empty input changed
  // to do this, value setting/changing has to be in parent state, right?
  // otherwise, will have the cursor disappearing issue
  // no, can just have value for if it's changed
  // and empty and setEmpty properties
  // what about the second goal's input losing focus on rerender
  // #endregion
  // <Goal
  //   // inputRef={newGoalRef}
  //   parent={parent}
  //   setEmpty={setEmpty}
  // />
  // Object.keys(focused)
  //   .reduce((acc, cur) => [...acc], [])
  //   .map((el, i) => (
  //     // <Goal key={i} parent={parent} />
  //     <GoalInput
  //     //   body
  //     // onBlur={}
  //     // onChange={}
  //     />
  //   ))
}

{
  // {!empty && (
  //     // but this has flash (re-render)
  //     // better way is to render a variable number of instances of <Goal />
  //     // as long as final goal is non-empty and parent goal is selected
  //     // then keep rendering new
  //     // then save in a batch on blur
  //     // can have a map
  //     // on focus, add index to map
  //     // on blur, remove index from map
  //     // on each blur, chck lenght of object.keys(map)
  //     // if lenght is zero, none of goals are focused
  //     // if lenght is non-zero, one of goals is focused
  //     // should selected be based off of focus on one of the inputs or buttons?
  //     <input
  //       onFocus={() => {
  //         // why doesn't this fire anymore?
  //         // renders based on value of  empty
  //         // and setEmpty from blur hides this before onFocus fires?
  //         //
  //         // setFocus(true);
  //         //
  //         // setting empty true in blur of goal
  //         // makes it render after
  //         // so instead, if we useeffect on empty
  //         // but how only do it for setempties after input.onfocus?
  //         // setEmpty(true);
  //         // setTimeout(() => newGoalRef.current?.focus?.(), 1);
  //         // newGoalRef.current?.focus?.();
  //       }}
  //       placeholder="Goale"
  //       style={{
  //         marginLeft: 16,
  //         marginTop: 16,
  //       }}
  //     />
  //   )}
}

// prevent re-render flash
// when click sibling goal input

// state needs to be held in parent, right?

// function Goal({ id }) {
//     return <div>
//         <input>
//         <Goals parent={id} />
//     </div>
// }

// function Goals({ parent }) {
//     return <div>
//         { goalsMap.filter(goal => goal.parent === parent).map(id => <Goal id={id} />)}
//         { focused.reduce((acc, cur) => [], [0]).map()}
//     </div>
// }
