import { connect } from "react-redux";

// goal, break
export default connect(
  ({}) => ({
    //
  }),
  {
    //
  },
)(function Timers(
  {
    //tGoals,
    //
  },
) {
  return (
    <>
      {
        //
      }
    </>
  );
});
