import { useState } from "react";
import { connect } from "react-redux";
import Div from "./Div.js";
import useViewport from "./hooks/useViewport.js";
import { useEffect } from "react";
import { useRef } from "react";

export default connect(
  ({}, {}) => ({}),
  {},
)(function Home({}) {
  let audioContext = null;
  const intervalID = useRef();

  const { height, width } = useViewport();

  const [duration, setDuration] = useState(
    JSON.parse(window.localStorage.getItem("duration")) ?? 175,
  );
  const [expiration, setExpiration] = useState(null);
  const [goals, setGoals] = useState(
    JSON.parse(window.localStorage.getItem("goals")) ?? [""],
  );
  const [minutes, setMinutes] = useState(
    JSON.parse(window.localStorage.getItem("minutes")) ?? 20,
  );
  const [now, setNow] = useState(new Date());
  const [showMore, setShowMore] = useState(false);
  const [stage, setStage] = useState(STAGE_INPUT);
  const [volume, setVolume] = useState(
    JSON.parse(window.localStorage.getItem("volume")) ?? 0.75,
  );

  useEffect(() => {
    window.localStorage.setItem("duration", JSON.stringify(duration));
  }, [duration]);
  useEffect(() => {
    window.localStorage.setItem("goals", JSON.stringify(goals));
  }, [goals]);
  useEffect(() => {
    window.localStorage.setItem("minutes", JSON.stringify(minutes));
  }, [minutes]);
  useEffect(() => {
    window.localStorage.setItem("volume", JSON.stringify(volume));
  }, [volume]);

  useEffect(() => {
    return () => {
      if (audioContext === null) {
        return;
      }
      audioContext.close();
      audioContext = null;
    };
  }, []);

  useEffect(() => {
    return () => clearInterval(intervalID.current);
  }, []);

  useEffect(() => {
    switch (stage) {
      case STAGE_TIMED:
        break;
      default:
        clearInterval(intervalID.current);
        return;
    }
    if (new Date() >= expiration) {
      clearInterval(intervalID.current);
      setNow(expiration);
      _beep({ duration, volume });
      setStage(STAGE_INPUT);
      return;
    }
  }, [now, stage]);

  if (stage === STAGE_INPUT) {
    return (
      <Div style={{ alignItems: "center" }}>
        <Div style={{ justifyContent: "center" }}>
          <label htmlFor="goal" style={{ fontSize: 36, marginBottom: 16 }}>
            What would you like to work on?
          </label>
          {goals.map((goal, i) => (
            <Div
              key={i}
              style={{
                alignItems: "center",
                flexDirection: "row",
                flexBasis: "auto",
                flexGrow: 0,
                flexShrink: 1,
                marginTop: 16,
              }}
            >
              <button
                onClick={
                  i === 0
                    ? () => setGoals([""])
                    : () => setGoals([...goals.slice(0, i)])
                }
                style={{
                  backgroundColor: "red",
                  color: "white",
                }}
              >
                x
              </button>
              <input
                id={i === goals.length - 1 ? "goal" : "goal" + i}
                key={i}
                onChange={e => onChangeGoals({ i, text: e.target.value })}
                size={20}
                style={{
                  fontSize: 24,
                  marginLeft: (i + 1) * 16,
                  maxWidth:
                    Math.min(960, width) - 21 - (i + 1) * 16 - 32 - 4 - 4, // button, buttonMargin subgoal marginLeft, webapp marginHorizontal, borderHorizontal, ?
                }}
                type="text"
                value={goals[i]}
              />
            </Div>
          ))}
        </Div>
        <Div
          style={{
            flexBasis: "auto",
            flexGrow: 0,
            flexShrink: 1,
            margin: 16,
            width: "100%",
          }}
        >
          <Div
            style={{
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-around",
              flexBasis: "auto",
              flexGrow: 0,
              flexShrink: 1,
              marginBottom: 16,
            }}
          >
            <button
              onClick={() => setGoals([...goals, ""])}
              style={{
                fontSize: 16,
                padding: 16,
              }}
            >
              Add sub-goal
            </button>
            <button
              onClick={onClickStart}
              style={{
                color: "#ddd",
                backgroundColor: "#363",
                fontSize: 16,
                padding: 16,
              }}
            >
              Start
            </button>
          </Div>
          {showMore && (
            <Div
              style={{
                // alignItems: "center",
                flexBasis: "auto",
                flexGrow: 0,
                flexShrink: 1,
                justifyContent: "space-around",
                marginBottom: 16,
              }}
            >
              <Div
                style={{
                  alignItems: "center",
                  flexBasis: "auto",
                  flexDirection: "row",
                  flexGrow: 0,
                  flexShrink: 1,
                  marginBottom: 16,
                }}
              >
                <label for="volume">Volume:&nbsp;</label>
                <input
                  id="volume"
                  max={1}
                  min={0}
                  onChange={onChangeVolume}
                  step={0.01}
                  type="range"
                  value={volume}
                />
                <label for="volume">&nbsp;{Math.round(volume * 100)}%</label>
              </Div>
              <Div
                style={{
                  alignItems: "center",
                  flexBasis: "auto",
                  flexDirection: "row",
                  flexGrow: 0,
                  flexShrink: 1,
                }}
              >
                <label for="duration">Duration:&nbsp;</label>
                <input
                  id="duration"
                  max={0}
                  min={999999}
                  onChange={onChangeDuration}
                  size={4}
                  style={{ textAlign: "right" }}
                  type="text"
                  value={duration}
                />
                <label for="duration">ms</label>
              </Div>
            </Div>
          )}
          <Div
            style={{
              alignItems: "center",
              flexBasis: "auto",
              flexDirection: "row",
              flexGrow: 0,
              flexShrink: 1,
              justifyContent: "space-around",
              marginTop: 8,
            }}
          >
            <button
              onClick={() => setShowMore(!showMore)}
              style={{
                fontSize: 48,
                paddingBottom: 12,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              ☰
            </button>
            <Div
              style={{
                // alignItems: "center",
                // flexBasis: "auto",
                // flexGrow: 0,
                // flexShrink: 1,
                marginLeft: 16,
              }}
            >
              <input
                max={180}
                min={1}
                name="minutes"
                onChange={onChangeMinutes}
                type="range"
                value={minutes}
              />
              <Div
                style={{
                  alignItems: "center",
                  flexBasis: "auto",
                  flexDirection: "row",
                  flexGrow: 0,
                  flexShrink: 1,
                  marginTop: 16,
                }}
              >
                <input
                  id="minutes"
                  max={180}
                  min={1}
                  onChange={onChangeMinutes}
                  size={3}
                  style={{ fontSize: 24, textAlign: "center" }}
                  type="number"
                  value={minutes}
                />
                <label htmlFor="minutes">
                  &nbsp;minute{minutes === "1" ? "" : "s"}
                </label>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
    );
  }

  if (stage === STAGE_TIMED) {
    return (
      <>
        <Div
          style={{
            alignItems:
              goals[goals.length - 1] === "break" ? "center" : "flex-start",
            flexGrow: 2,
            fontSize: goals[goals.length - 1] === "break" ? 64 : 32,
            justifyContent:
              goals[goals.length - 1] === "break" ? "center" : "flex-start",
          }}
        >
          {goals[goals.length - 1]}
        </Div>
        <Div
          style={{
            alignItems: "center",
            flexGrow: 3,
            fontSize: 64,
            justifyContent: "center",
          }}
        >
          {expiration - now < 60 * 60 * 1000
            ? ""
            : `${Math.max(
                0,
                Math.floor((expiration - now) / (60 * 60 * 1000)),
              )}:`}
          {expiration - now < 60 * 60 * 1000
            ? Math.max(
                0,
                Math.floor(
                  ((expiration - now) % (60 * 60 * 1000)) / (60 * 1000),
                ),
              )
            : (
                Math.max(
                  0,
                  Math.floor(
                    ((expiration - now) % (60 * 60 * 1000)) / (60 * 1000),
                  ),
                ) + ""
              ).padStart(2, 0)}
          :
          {(
            Math.max(0, Math.floor(((expiration - now) % (60 * 1000)) / 1000)) +
            ""
          ).padStart(2, 0)}
        </Div>
        <Div
          style={{
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <button
            onClick={() =>
              setExpiration(new Date(expiration.getTime() + 5 * 60 * 1000))
            }
            style={{
              fontSize: 16,
              padding: 16,
            }}
          >
            Add 5 minutes
          </button>
          <button
            onClick={() =>
              setExpiration(new Date(expiration.getTime() - 5 * 60 * 1000))
            }
            style={{
              fontSize: 16,
              padding: 16,
            }}
          >
            Subtract 5 minutes
          </button>
        </Div>
        <Div
          style={{
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <button
            onClick={() =>
              setExpiration(new Date(expiration.getTime() + 60 * 1000))
            }
            style={{
              fontSize: 16,
              padding: 16,
            }}
          >
            Add minute
          </button>
          <button
            onClick={() =>
              setExpiration(new Date(expiration.getTime() - 60 * 1000))
            }
            style={{
              fontSize: 16,
              padding: 16,
            }}
          >
            Subtract minute
          </button>
        </Div>
        <Div>
          <button
            onClick={() => {
              if (goals[goals.length - 1] === "break") {
                setGoals([...goals.slice(goals.length - 1)]);
              }
              setStage(STAGE_INPUT);
            }}
            style={{
              backgroundColor: "#822",
              color: "white",
              fontSize: 16,
              padding: 16,
            }}
          >
            Stop
          </button>
        </Div>
        <Div>
          <button
            onClick={() => {
              if (goals[goals.length - 1] !== "break") {
                setGoals([...goals.slice(0, -1), "break"]);
                const s = new Date();
                setExpiration(new Date(s.getTime() + 5 * 60 * 1000));
                setNow(s);
                return;
              }
              if (goals.length === 1) {
                setGoals([""]);
                setStage(STAGE_INPUT);
                return;
              }
              setGoals([...goals.slice(0, -1)]);
              const s = new Date();
              setExpiration(new Date(s.getTime() + minutes * 60 * 1000));
              setNow(s);
              return;
            }}
            style={{
              backgroundColor: "#363",
              color: "white",
              fontSize: 16,
              padding: 16,
            }}
          >
            Complete
          </button>
        </Div>
      </>
    );
  }

  return <div>unknown stage</div>;

  function onChangeDuration(event) {
    setDuration(event.target.value);
  }

  function onChangeGoals({ i, text }) {
    setGoals([...goals.slice(0, i), text, ...goals.slice(i + 1)]);
  }

  function onChangeMinutes(event) {
    setMinutes(event.target.value);
  }

  function onChangeVolume(event) {
    setVolume(event.target.value);
  }

  function onClickStart() {
    clearInterval(intervalID.current);
    const s = new Date();
    setNow(s);
    setExpiration(new Date(s.getTime() + minutes * 60 * 1000));
    intervalID.current = setInterval(tick, 1000);
    setStage(STAGE_TIMED);
  }

  function tick() {
    setNow(new Date());
  }

  function _beep({
    duration = 175,
    frequency = 1750,
    volume = 0.75,
    type = `sine`,
    callback = () => {},
  } = {}) {
    if (audioContext == null) {
      audioContext = new (window.AudioContext ||
        window.webkitAudioContext ||
        window.audioContext)();
    }

    const now = audioContext.currentTime;
    const oscillator = audioContext.createOscillator();
    const gainNode = audioContext.createGain();

    oscillator.connect(gainNode);
    gainNode.connect(audioContext.destination);
    gainNode.gain.value = volume;
    oscillator.frequency.value = frequency;
    oscillator.type = type;
    oscillator.onended = callback;
    oscillator.start(now);
    oscillator.stop(now + duration / 1000); // oscillator uses seconds instead of ms
  }
});

const STAGE_INPUT = "input";
const STAGE_TIMED = "work";
// const STAGE_CHECKIN = "checkin";
